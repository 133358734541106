<template>
    <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
        <div id="doctor-list" style="min-height: 100vh;" ref="listView">
            <div class="booking-banner">
                <h2 class="toolbar-title">醫師列表</h2>
            </div>
            <div class="booking-search-wrapper">
                <div class="booking-search">
                    <span class="icon-search"></span>
                    <input type="text" class="keyword" placeholder="請輸入醫師姓名/診所名稱/地區" v-model="keyword" @keyup="onKeyEnter">
                    <div class="btn-search" @click="doSearch">搜索</div>
                </div>
                <router-link to="/hospital/list" class="link-item">診所列表</router-link>
            </div>
            <div class="booking-tabs-w">
                <ul class="doctor-filter-tags" v-if="categorys.length">
                    <li :class="{'doctor-filter-tag': true, current: filterType==0, 'filter-up': false }" @click="changeFilter(0, 0)">綜合</li>
                    <!-- <li :class="{'doctor-filter-tag': true, current: filterType==1, 'filter-up': false }" @click="changeFilter(1, 0)">預約</li> -->
                    <li v-for="category in categorys"
                        :key="category.codeid" 
                        v-text="category.codedesc" 
                        :class="{
                            'doctor-filter-tag': true, 
                            'current': filterType==3 && filterValue==category.codeid,
                            'filter-up': false
                        }"
                        @click="changeFilter(3, category.codeid)"
                        >
                    </li>
                </ul>
            </div>
            <div class="booking-tabviews">
                <div class="tabview-item" v-for="(doctor, index) in dataList" :key="index">
                    <router-link :to="`/doctor/${doctor.globaluserid}`" :class="{'icon-gender': true, 'man': doctor.sex==1}"></router-link>
                    <router-link :class="{'doctor-name': true, 'fill': doctor.isdisplayorder != true}" v-text="doctor.username" :to="`/doctor/${doctor.globaluserid}`"></router-link>
                    <div :class="{'booking-count': true, 'show': doctor.isdisplayorder == true}" v-text="`已約${doctor.totalordercount}次`"></div>
                    <div class="doctor-address-list">
                        <router-link class="doctor-address-item" :to="`/doctor/list?AreaLv1=${area.arealevel1id}&AreaLv2=${area.arealevel2id}`" v-for="(area, i) in doctor.areaList" :key="i" v-text="area.areaname"></router-link>
                    </div>
                    <span @click="onBookingClick(doctor)" class="btn-booking">預約</span>
                </div>
            </div>
            <div class="load-more" v-if="isLoadMore">
				<div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
				<span class="text">正在加载...</span>
			</div>
			<div class="load-finished" v-if="isLoadFinished">已全部加載</div>
        </div>
        <loading v-if="loading" style="padding-top: 200px;"/>
    </van-pull-refresh>
</template>

<script>
    import doctor_list from '@/views/mixins/doctor_list'
    import Loading from '@/layouts/Loading.vue'
	export default {
        mixins: [doctor_list],
        components: {
            Loading,
        },
        data: function(){
            return {
                pageNo: 1,
                pageSize: 20,
                dataList: [],
				loading: true,
				isRefresh: false,
				isLoadMore: false,
                isLoadFinished: false,
				maxPage: 0,
                clientId: this.$store.getters.clientId,
                
                filterType: 0,
                arealevel1id: 0,
                arealevel2id: 0,
                keyword: '',
                sortdesc: null,
                filterValue: null,
                categorys: this.$store.getters.askCategorys || [],
            }
        },
        mounted: function(){
            var hash = this.$route.hash
            hash = hash.replace('#', '')
            var list = hash.split('&')
            var key = list[0].split('=')[0]
            var value = list[0].split('=')[1] / 1
            if(key == 'page') {
                if(! isNaN(value)) {
                    this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                }
            }
            this.arealevel1id = this.$route.query.AreaLv1
            this.arealevel2id = this.$route.query.AreaLv2
            this.filterValue = this.$route.query.IllCate
            if(this.filterValue) {
                this.filterType = 3
            }
            this.onRefresh()
        },
        methods: {
            onKeyEnter: function(key) {
                if(key.key === 'Enter' || key.key === 'Search') {
                    this.doSearch()
                }
            },
            doSearch: function(){
                this.pageNo = 1
                this.onRefresh()
            },
            changeFilter: function(filterType, filterValue) {
                this.filterType = filterType
                this.filterValue = filterValue
                this.isAsc = true
                this.pageNo = 1
                this.arealevel1id = null
                this.arealevel2id = null
                this.$router.push({path: '/doctor/list'},(e)=>{});
                this.visible = false
                this.dataList = []
                this.onRefresh()
            },
            onBookingClick: function(doctor) {
                if(this.clientId) {
                    this.$router.push({name: 'bookingEdit', params: {'doctor': doctor}})
                } else {
                    this._toast.warning(this, '未登錄，請先登錄')
                    setTimeout(() => {
                        this.$router.push('/login')
                    }, 1500)
                }
            },
            onRefresh: function(){
                this.loading = true
                this.loadData({
                    arealevel1id: this.arealevel1id || undefined,
                    arealevel2id: this.arealevel2id || undefined,
                    keyword: this.keyword || undefined,
                    pageno: this.pageNo,
                    pagesize: this.pageSize,
                    sortdesc: this.sortdesc || undefined,
                    sorttypeid: this.filterValue || undefined
				}, (data, error)=>{
                    this.loading = false
					this.isRefresh = false
					this.dataList = (data && data.list) || []
					var total = (data && data.total) || 0
					if(total > 0) {
						this.maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
						if(this.pageNo >= this.maxPage) {
							this.isLoadFinished = true
						}
					} else {
						if(!error) {
							this.isLoadFinished = true
						}
					}
                })
            },
            handleScroll (event) {
				if(this.$refs.listView.clientHeight < window.innerHeight) return
				if((window.innerHeight + window.pageYOffset) > this.$refs.listView.clientHeight) {
					// 加载更多
					if(!this.loading && !this.isLoadMore && this.pageNo < this.maxPage) {
						this.isLoadMore = true
						this.loading = true
						this.loadData({
							arealevel1id: this.arealevel1id || undefined,
                            arealevel2id: this.arealevel2id || undefined,
                            keyword: this.keyword || undefined,
                            pageno: this.pageNo + 1,
                            pagesize: this.pageSize,
                            sortdesc: this.sortdesc || undefined,
                            sorttypeid: this.filterValue || undefined
						}, (data, error)=>{
							this.loading = false
							this.isLoadMore = false
							this.dataList = this.dataList.concat((data && data.list) || [])
							if(!error) {
								this.pageNo = this.pageNo + 1
								var total = (data && data.total) || 0
								if(total > 0) {
									var maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
									if(this.pageNo >= maxPage) {
										this.isLoadFinished = true
									}
								}
							}
						})
					}
				}
			}
		},
		created () {
            window.addEventListener('scroll', this.handleScroll);
            
            if(!this.categorys.length) {
                this.$store.dispatch('getAskCategorys', {'langcode': 'B5'})
                .then((rs)=>{
                    this.categorys = rs || []
                }) 
            }
		},
		destroyed () {
			window.removeEventListener('scroll', this.handleScroll);
        },
        watch: {
            $route(to,from){
                var hash = this.$route.hash
                if(hash) {
                    hash = hash.replace('#', '')
                    var list = hash.split('&')
                    var key = list[0].split('=')[0]
                    var value = list[0].split('=')[1] / 1
                    if(key == 'page') {
                        if(! isNaN(value)) {
                            this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                            this.visible = false
                        }
                    } else {
                        this.pageNo = 1
                    }
                } else {
                    this.pageNo = 1
                }
                this.keyword = this.$route.query.keyword || undefined
                this.arealevel1id = parseInt(this.$route.query.AreaLv1)
                this.arealevel2id = parseInt(this.$route.query.AreaLv2)
                this.filterValue = this.$route.query.IllCate
                if(!this.filterValue) {
                    this.filterType = 0
                }
                this.onRefresh()
            }
        }
    }
</script>

<style lang="scss" scoped>

    .booking-banner {
        display: block;
        margin: 11px 24px 0px 24px;
        background-image: url(../../../assets/imgs/mobile/doctor-list/img_banneryy@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 194px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .booking-banner > .toolbar-title {
        display: inline-block;
        width: 334px;
        height: 91px;
        line-height: 91px;
        text-align: center;
        background-color: rgba(5, 27, 48, .4);
        font-size: 54px;
        font-weight: bold;
        color: #fff;
    }

    .booking-search-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .booking-search {
        flex: 1;
        display: flex;
        flex-direction: row;
        margin: 16px 24px;
        background-color: #fff;
        height: 56px;
        border-radius: 16px;
    }

    .booking-search > .icon-search {
        display: inline-block;
        width: 21px;
        height: 21px;
        margin: 18px 15px 17px 19px;
        background-image: url(../../../assets/imgs/mobile/doctor-list/icon_search@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .booking-search > input.keyword {
        height: 56px;
        flex: 1;
        font-size: 28px;
        font-weight: 400;
        color: #231F20;
    }

    .booking-search > input.keyword::placeholder {
        font-size: 24px;
        color: #969696;
        font-weight: 400;
    }

    .booking-search > .btn-search {
        opacity: 1;
        width: 114px;
        height: 56px;
        line-height: 56px;
        text-align: center;
        color: #fff;
        font-weight: bold;
        font-size: 32px;
        background-color: #36C4D0;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
    }

    .booking-search > .btn-search::selection {
        color: #fff;
    }

    .booking-search > .btn-search:active {
        opacity: 9;
    }

    .booking-search-wrapper .link-item {
        height: 56px;
        line-height: 56px;
        font-size: 30px;
        font-weight: 500;
        margin-right: 24px;
        text-decoration: underline;
    }

    .booking-tabs-w {
        margin: 0px 24px;
        overflow-x: scroll;
        background-color: #fff;
        position: relative;
        height: 90px;
    }

    .booking-tabs-w::-webkit-scrollbar {
        display: none;
    }

    .booking-tabs-w > .doctor-filter-tags {
        white-space: nowrap;
        height: 90px;
        line-height: 90px;
        padding-left: 12px;
        padding-right: 12px;
    }

    .booking-tabs-w > .doctor-filter-tags::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0.1px;
        border-bottom: 1px solid #DCF7FB;
    }

    .booking-tabs-w > .doctor-filter-tags > .doctor-filter-tag {
        display: inline-block;
        height: 42px;
        line-height: 42px;
        text-align: center;
        cursor: pointer;
        background-color: #fff;
        font-size: 30px;
        font-weight: 400;
        color: #242020;
        padding-right: 20px;
    }

    .booking-tabs-w > .doctor-filter-tags > .doctor-filter-tag.filter-up {
        position: relative;
    }

    .booking-tabs-w > .doctor-filter-tags > .doctor-filter-tag.current.filter-up::before {
        position: absolute;
        height: 7px;
        width: 10px;
        content: '';
        right: 8px;
        top: 50%;
        background-image: url(../../../assets/imgs/pc/img_cxial@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        transform: translateY(-50%);
    }

    .booking-tabs-w > .doctor-filter-tags > .doctor-filter-tag.current {
        position: relative;
        color: #36C4D0;
        font-weight: bold;
    }

    .booking-tabs-w > .doctor-filter-tags > .doctor-filter-tag.current::after {
        position: absolute;
        height: 4px;
        width: 22px;
        content: '';
        left: 50%;
        bottom: 0;
        background-color: #36C4D0;
        transform: translateX(-19px);
    }


    .booking-tabs {
        position: absolute;
        display: flex;
        flex-direction: row;
        white-space: nowrap;
        height: 90px;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 19px;
    }

    .booking-tabs .booking-tab {
        display: inline-block;
        color: #636363;
        font-weight: 400;
        margin-right: 38px;
        font-size: 28px;
    }

    .booking-tabs .booking-tab.current {
        color: #231F20;
        font-size: 32px;
        font-weight: bold;
        position: relative;
        padding-right: 20px;
        padding-bottom: 22px;
    }

    .booking-tabs .booking-tab.current > .c-name {
        display: inline-block;
        position: relative;
    }

    .booking-tabs .booking-tab.current > .c-name::before {
        content: '';
        position: absolute;
        width: 48px;
        height: 10px;
        left: 50%;
        bottom: -20px;
        transform: translateX(-50%);
        border-radius: 05px;
        background-color: #36C4D0;
    }

    .booking-tabs .booking-tab.current > .arrow {
        content: '';
        position: absolute;
        width: 10px;
        height: 07px;
        background-image: url(../../../assets/imgs/mobile/doctor-list/icon_shaix@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        right: 0;
        top: 13px;
    }

    .booking-tabviews {
        display: flex;
        flex-direction: column;
        margin: 01px 24px 0px 24px;
        position: relative;
    }

    .booking-tabviews > .tabview-item {
        display: flex;
        flex-direction: row;
        position: relative;
        padding: 30px 0px;
        border-bottom: 1px solid #ddd;
        background-color: #fff;
    }

    .booking-tabviews > .tabview-item > .icon-gender {
        width: 80px;
        height: 80px;
        margin-left: 19px;
        background-image: url(../../../assets/imgs/mobile/doctor-list/img_nvys@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .booking-tabviews > .tabview-item > .icon-gender.man {
        background-image: url(../../../assets/imgs/mobile/doctor-list/img_nanys@2x.png);
    }

    .booking-tabviews > .tabview-item > .doctor-address-list {
        position: absolute;
        display: flex;
        flex-direction: row;
        left: 113px;
        top: 87px;
        padding-left: 31px;
    }

    .booking-tabviews > .tabview-item > .doctor-address-list::before {
        content: '';
        width: 14px;
        height: 20px;
        top: 0;
        left: 0;
        position: absolute;
        background-image: url(../../../assets/imgs/mobile/doctor-list/icon_zuob@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .booking-tabviews > .tabview-item > .doctor-address-list > .doctor-address-item {
        color: #969696;
        font-size: 24px;
        line-height: 23px;
        font-weight: 400;
        margin-right: 23px;
    }

    .booking-tabviews > .tabview-item > .doctor-name {
        color: #231F20;
        font-size: 34px;
        font-weight: bold;
        line-height: 32px;
        margin-left: 14px;
    }

    .booking-tabviews > .tabview-item > .doctor-name.fill {
        flex: 1;
    }

    .booking-tabviews > .tabview-item > .booking-count {
        flex: 1;
        color: #FF8000;
        font-size: 24px;
        font-weight: bold;
        line-height: 24px;
        margin-left: 36px;
        margin-top: 6px;
        display: none;
    }

    .booking-tabviews > .tabview-item > .booking-count.show {
        display: block;
    }

    .booking-tabviews > .tabview-item > .btn-booking {
        background-color: #FFE3D5;
        border-radius: 04px;
        width: 96px;
        line-height: 48px;
        height: 48px;
        text-align: center;
        font-size: 26px;
        color: #FF8000;
        font-weight: bold;
        margin-right: 48px;
    }

	.load-finished {
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		font-size: 24px;
		font-weight: 400;
		color: #99999991;
	}

	.load-finished::before {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		right: -130px;
		margin-right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	.load-finished::after {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		left: -130px;
		top: 50%;
		margin-left: 20px;
		transform: translateY(-50%);
	}

	// loadmore
	.load-more {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.load-more > .text {
		margin-left: 24px;
	}


	.lds-default {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-default div {
		position: absolute;
		width: 6px;
		height: 6px;
		background: #36C4D0;
		border-radius: 50%;
		animation: lds-default 1.2s linear infinite;
	}
	.lds-default div:nth-child(1) {
		animation-delay: 0s;
		top: 37px;
		left: 66px;
	}
	.lds-default div:nth-child(2) {
		animation-delay: -0.1s;
		top: 22px;
		left: 62px;
	}
	.lds-default div:nth-child(3) {
		animation-delay: -0.2s;
		top: 11px;
		left: 52px;
	}
	.lds-default div:nth-child(4) {
		animation-delay: -0.3s;
		top: 7px;
		left: 37px;
	}
	.lds-default div:nth-child(5) {
		animation-delay: -0.4s;
		top: 11px;
		left: 22px;
	}
	.lds-default div:nth-child(6) {
		animation-delay: -0.5s;
		top: 22px;
		left: 11px;
	}
	.lds-default div:nth-child(7) {
		animation-delay: -0.6s;
		top: 37px;
		left: 7px;
	}
	.lds-default div:nth-child(8) {
		animation-delay: -0.7s;
		top: 52px;
		left: 11px;
	}
	.lds-default div:nth-child(9) {
		animation-delay: -0.8s;
		top: 62px;
		left: 22px;
	}
	.lds-default div:nth-child(10) {
		animation-delay: -0.9s;
		top: 66px;
		left: 37px;
	}
	.lds-default div:nth-child(11) {
		animation-delay: -1s;
		top: 62px;
		left: 52px;
	}
	.lds-default div:nth-child(12) {
		animation-delay: -1.1s;
		top: 52px;
		left: 62px;
	}
	@keyframes lds-default {
		0%, 20%, 80%, 100% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.5);
		}
	}
</style>
